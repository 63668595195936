import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">
        &copy;  AixWebMedia GmbH - {new Date().getFullYear()} &nbsp;
        <a
          onClick={() => {
            props.onOpenArticle('legal')
          }}
        >
          Impressum – Legal Disclosure
        </a>
        </p>
    </footer>
)

Footer.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Footer
