import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span><svg xmlns="http://www.w3.org/2000/svg" fill="currentcolor" width="75%" height="100%" viewBox="0 0 512 512"><path d="M16 128h416c8.84 0 16-7.16 16-16V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v32c0 8.84 7.16 16 16 16zm480 96H80c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zm-64 160H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"/></svg></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>AixWebMedia GmbH</h1>
        <p>devops, digital, agile and media project consultancy</p>
      </div>
    </div>
    <nav>
      <ul>
        {/* <li> */}
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            contact
          </button>
        {/* </li> */}
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
